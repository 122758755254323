
/*
  
  SASS Imports

*/

@import './_mixins.scss';
@import './_animations.scss';


/*

Defaults

*/

*{
  box-sizing: border-box;
}

html,body{
  font-family:'Space Mono',sans-serif;

  padding:0;
  margin:0;
  font-size:10px;
}

html{
    background:#f5f0eb;
    //background:#F8F2E3;
}

.App{
  padding:40px;
  font-size:1.2rem;
  line-height: 2rem;

  h1,h2{
    font-size:1.2rem;
    text-align:center;
    margin:0;
  }

  h1{
    font-weight:bold;
    text-transform: uppercase;
  }

  h2{
    font-weight:normal;
    text-transform: uppercase;
  }
}

.App-head{
  padding:10vh 0;
}

.App-footer{
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  height:0;
  background-image: url("../assets/footer.png");
  background-size:100% auto;
  z-index:5;
}

.App-message-replies{
  padding:20px 0;
}
.App-message{

  //border:2px solid;
  position:relative;
      width:95%;
     margin:0 auto;
     max-width:900px;
     padding-left:100px;

     .App-message-author{
      font-weight:bold;
      display:inline-block;
      padding-right:0.6em;
      font-size:1rem;
    line-height: 2rem;
    text-transform: uppercase;
     }
  .App-message-meta{
    font-weight:bold;
    position:absolute;
    top:0;
    left:0;
    font-size:1rem;
    line-height: 2rem;
    text-align:right;
    transform: translateX(-50%);
    width:150px;
    //padding-top:.3rem;
  }
  .App-message-bubble{
    //border-top:1px dashed;
    //padding-top:10px;
    padding-bottom:5rem;
  
  }

  

  img{
    /*display:inline-block;
    width:100px;
    position:absolute;
    z-index:0;
    top:0;
    left:20%;
    mix-blend-mode: multiply;*/
    width:100px;
    margin-top:1rem;
    margin-left:5%;
    filter:grayscale(50%);
    
  }

  &:nth-child(odd){

    & > div{

    }


    img{
      transform: rotateZ(2deg);
    }
  }

  &:nth-child(even){
    
    & > div{
       
    }

    img{
      transform: rotateZ(-2deg);
    }
  }
}

.App-images{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:-1;

  div{
    width:100%;
    height:100%;
  }


  .App-image{
    position:fixed;
   top:100%;
    left:0;
    right:0;
    bottom:0;
    left:0%;

    img{
      position:absolute;
      top:0%;
      left:50%;
      width:50vw;
      transform:translateX(-50%);
    }
  }

  .App-image--0{
    img{
      left:70%;
      width:30vw;
    }
  }

  .App-image--1{
    img{
      left:10%;
      width:20vw;
    }
  }

  .App-image--2{
    img{
      left:20%;
      width:15vw;
    }
  }

  .App-image--3{
    img{
      left:50%;
      width:30vw;
    }
  }

  .App-image--4{
    img{
      left:60%;
      width:10vw;
    }
  }

  .App-image--5{
    img{
      left:18%;
      width:30vw;
    }
  }

  .App-image--6{
    img{
      left:32%;
      width:5vw;
    }
  }

  .App-image--7{
    img{
      left:90%;
      width:10vw;
    }
  }

  .App-image--8{
    img{
      left:2%;
      width:16vw;
    }
  }

  .App-image--9{
    img{
      left:50%;
      width:5vw;
    }
  }



}